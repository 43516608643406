import { iconButtonClasses, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useObservableState } from 'observable-hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@/components/Icon';
import SnoozeConversationDialog from '@/pages/InboxRXJS/ConversationDropdown/SnoozeConversationDialog';
import { ConversationWrapper } from '@/services/conversations/managers/conversation-wrapper';
import {
  usePinConversationMutation,
  useUnpinConversationMutation,
  useCloseConversationMutation,
  useOpenConversationMutation,
  useReadConversationMutation,
  useUnreadConversationMutation,
} from '@/api/conversation';
import { useDebugMode } from '@/utils/useDebugMode';
import { DeleteContactConfirmationDialog } from './DeleteContactConfirmationDialog';

interface ConversationDropdownProps {
  conversation: ConversationWrapper;
  buttonStyle?: React.CSSProperties;
}

const ConversationDropdown: React.FC<ConversationDropdownProps> = ({
  conversation,
  buttonStyle,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: unpinConversation } = useUnpinConversationMutation(
    conversation.getId(),
  );
  const { mutateAsync: pinConversation } = usePinConversationMutation(
    conversation.getId(),
  );
  const { mutateAsync: openConversation } = useOpenConversationMutation(
    conversation.getId(),
  );
  const { mutateAsync: closeConversation } = useCloseConversationMutation(
    conversation.getId(),
  );
  const { mutateAsync: readConversation } = useReadConversationMutation(
    conversation.getId(),
  );
  const { mutateAsync: unreadConversation } = useUnreadConversationMutation(
    conversation.getId(),
  );

  const debugMode = useDebugMode((s) => s.debugMode);

  const [isSnoozeConversationModalOpen, setIsSnoozeConversationModalOpen] =
    useState(false);
  const [isDeleteConversationModalOpen, setIsDeleteConversationModalOpen] =
    useState(false);

  const unreadMessageCount = useObservableState(
    conversation.getUnreadMessageCount$(),
    null,
  );
  const isPinned = useObservableState(conversation.getIsPinned$(), null);
  const status = useObservableState(conversation.getStatus$(), null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    if (event) {
      event.stopPropagation();
    }

    setAnchorEl(null);
  };

  const items = [
    {
      label: isPinned
        ? t('inbox.thread.unpin-conversation')
        : t('inbox.thread.pin-conversation'),
      onClick: () => {
        if (isPinned) {
          unpinConversation();
        } else {
          pinConversation();
        }
      },
    },
    {
      label:
        unreadMessageCount && unreadMessageCount > 0
          ? t('inbox.thread.mark-as-read')
          : t('inbox.thread.mark-as-unread'),
      onClick: () => {
        if (unreadMessageCount && unreadMessageCount > 0) {
          readConversation();
        } else {
          unreadConversation();
        }
      },
    },
    {
      label:
        status === 'closed' || status === 'pending'
          ? t('inbox.thread.open-conversation')
          : t('inbox.thread.close-conversation'),
      onClick: () => {
        if (status === 'closed' || status === 'pending') {
          openConversation();
        } else {
          closeConversation();
        }
      },
    },
    (status === 'open' || status === 'closed') && {
      label: t('inbox.thread.snooze-conversation'),
      onClick: () => {
        setIsSnoozeConversationModalOpen(true);
      },
    },
    debugMode && {
      label: t(
        'inbox.thread.delete-conversation',
        'Delete contact permanently',
      ),
      onClick: () => {
        setIsDeleteConversationModalOpen(true);
      },
      color: 'red',
    },
  ].filter(
    (item): item is { label: string; onClick: () => void; color?: string } =>
      !!item,
  );

  return (
    <>
      <Tooltip
        title={t('conversation-controls.dropdown', {
          defaultValue: 'More actions',
        })}
        placement="bottom"
      >
        <IconButton
          aria-label="more"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          className="more-actions-menu"
          sx={{
            ...(open && {
              [`&.${iconButtonClasses.root}.${iconButtonClasses.root}`]: {
                visibility: 'visible',
                opacity: 1,
              },
            }),
          }}
          style={buttonStyle}
          onClick={handleButtonClick}
        >
          <Icon icon="dots-horizontal" />
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.label}
            onClick={(e) => {
              e.stopPropagation();
              handleClose(undefined);
              item.onClick();
            }}
            {...(item.color === 'red' && {
              sx: {
                color: 'red.90',
                ':hover': {
                  color: 'red.90',
                  bgcolor: 'red.5',
                },
              },
            })}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <SnoozeConversationDialog
        isSnoozeConversationModalOpen={isSnoozeConversationModalOpen}
        setIsSnoozeConversationModalOpen={setIsSnoozeConversationModalOpen}
        conversation={conversation}
      />
      <DeleteContactConfirmationDialog
        open={isDeleteConversationModalOpen}
        onClose={() => setIsDeleteConversationModalOpen(false)}
        userProfileId={conversation.getUserProfileId()}
      />
    </>
  );
};

export default ConversationDropdown;
