import { Box } from '@mui/material';
import { useInjection } from 'inversify-react';
import { useObservable, useObservableEagerState } from 'observable-hooks';
import React, { useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Subject } from 'rxjs';

import ConversationListEmptyResult from '@/pages/InboxRXJS/ConversationLists/ConversationListEmptyResult';
import { getConversationListItemKey } from '@/pages/InboxRXJS/ConversationLists/ConversationListItem/ConversationListItem';
import ConversationVirtualListItem from '@/pages/InboxRXJS/ConversationLists/ConversationListItem/getConversationVirtualListItem';
import ConversationListSkeleton from '@/pages/InboxRXJS/ConversationLists/ConversationListSkeleton';
import ConversationListVirtualOuterElementType from '@/pages/InboxRXJS/ConversationLists/ConversationListVirtualOuterElementType';
import { ConversationWithUserProfileDataSourceManager } from '@/services/conversations/conversation-with-user-profile-data-source-manager';
import { GetConversationsFilter } from '@/services/conversations/models/get-conversations-filter';
import { DataSourceListRange } from '@/services/data-sources/models/data-source-list-range';

interface MainConversationListProps {
  getConversationsFilter: GetConversationsFilter;
}

const MainConversationList: React.FC<MainConversationListProps> = ({
  getConversationsFilter,
}) => {
  const conversationWithUserProfileDataSourceManager = useInjection(
    ConversationWithUserProfileDataSourceManager,
  );

  const virtualizerListRange$$ = useObservable(
    () => new Subject<DataSourceListRange>(),
  );

  const [, conversationWithUserProfileResults$, isInitializing$] =
    useMemo(() => {
      const dataSource =
        conversationWithUserProfileDataSourceManager.getOrInitDataSource(
          getConversationsFilter,
        );

      return [
        dataSource,
        dataSource.setupAndGet$(getConversationsFilter, virtualizerListRange$$),
        dataSource.getIsInitializing$(),
      ];
    }, [
      conversationWithUserProfileDataSourceManager,
      getConversationsFilter,
      virtualizerListRange$$,
    ]);
  const conversationWithUserProfileResults = useObservableEagerState(
    conversationWithUserProfileResults$,
  );

  const isInitializing = useObservableEagerState(isInitializing$);

  const fixedSizeListRef = useRef<FixedSizeList>(null);
  const fixedSizeListOuterRef = useRef<HTMLDivElement | null>(null);

  if (isInitializing) {
    return <ConversationListSkeleton />;
  }

  if (conversationWithUserProfileResults.length === 0) {
    return <ConversationListEmptyResult />;
  }

  return (
    <Box
      sx={{
        flex: '1 1 0px',
      }}
    >
      <AutoSizer>
        {({ height, width }: { width: number; height: number }) => (
          <FixedSizeList
            itemKey={(index, data) => {
              return getConversationListItemKey({
                conversationId: data[index].conversation.getId(),
                userProfileId: data[index].userProfile.getId(),
              });
            }}
            width={width}
            ref={fixedSizeListRef}
            itemSize={144}
            height={height}
            overscanCount={25}
            outerRef={fixedSizeListOuterRef}
            outerElementType={ConversationListVirtualOuterElementType}
            itemData={conversationWithUserProfileResults}
            itemCount={conversationWithUserProfileResults.length}
            onScroll={({ scrollDirection }) => {
              const scrollTop = fixedSizeListOuterRef.current?.scrollTop;
              const scrollHeight = fixedSizeListOuterRef.current?.scrollHeight;
              const clientHeight = fixedSizeListOuterRef.current?.clientHeight;

              if (!scrollTop || !scrollHeight || !clientHeight) {
                return;
              }
              const nearBottom = scrollHeight - scrollTop - clientHeight < 100;

              if (scrollDirection === 'forward' && nearBottom) {
                virtualizerListRange$$.next({
                  start: 0,
                  end: conversationWithUserProfileResults.length - 1,
                });
              }
            }}
          >
            {ConversationVirtualListItem}
          </FixedSizeList>
        )}
      </AutoSizer>
    </Box>
  );
};

export default React.memo(MainConversationList);
