import { createStore, useStore } from 'zustand';
import { EarlyAccessFeature } from '@/posthog/_generated/early-access-features';
import posthog from '@/posthog/index';

type PosthogStore = {
  allBetas: EarlyAccessFeature[];
  activeBetas: EarlyAccessFeature[];
  inactiveBetas: EarlyAccessFeature[];
  setActiveBetas: (activeBetas: EarlyAccessFeature[]) => void;
  setInactiveBetas: (inactiveBetas: EarlyAccessFeature[]) => void;
  setAllBetas: (allBetas: EarlyAccessFeature[]) => void;
  setUserBeta: (
    betaKey: EarlyAccessFeature['flagKey'],
    isEnabled: boolean,
  ) => void;
};

const posthogStore = createStore<PosthogStore>((setState) => {
  posthog.featureFlags.onFeatureFlags((activeFeatureFlags) => {
    posthog.getEarlyAccessFeatures((features) => {
      const activeBetas = features.filter((beta) => {
        if (!beta.flagKey) {
          return false;
        }
        return activeFeatureFlags.includes(beta.flagKey);
      }) as EarlyAccessFeature[];

      const inactiveBetas = features.filter((beta) => {
        if (!beta.flagKey) {
          return false;
        }
        return !activeFeatureFlags.includes(beta.flagKey);
      }) as EarlyAccessFeature[];

      setState({
        allBetas: features as EarlyAccessFeature[],
        activeBetas,
        inactiveBetas,
      });
    });
  });

  return {
    allBetas: [],
    activeBetas: [],
    inactiveBetas: [],
    setActiveBetas: (activeBetas) => {
      setState({ activeBetas });
    },
    setInactiveBetas: (inactiveBetas) => {
      setState({ inactiveBetas });
    },
    setAllBetas: (allBetas) => {
      setState({ allBetas });
    },

    setUserBeta: (betaKey, isEnabled) => {
      posthog.updateEarlyAccessFeatureEnrollment(betaKey, isEnabled);

      setState((s) => {
        const newItem = s.allBetas.find((item) => item?.flagKey === betaKey);
        const activeBetas = isEnabled
          ? [...s.activeBetas, ...(newItem ? [newItem] : [])]
          : s.activeBetas.filter((item) => item?.flagKey !== betaKey);

        const inactiveBetas = isEnabled
          ? s.inactiveBetas.filter((item) => item?.flagKey !== betaKey)
          : [...s.inactiveBetas, ...(newItem ? [newItem] : [])];

        return {
          activeBetas,
          inactiveBetas,
        };
      });

      posthog.reloadFeatureFlags();
    },
  };
});

export function usePosthogStore<T>(selector: (state: PosthogStore) => T) {
  return useStore(posthogStore, selector);
}
