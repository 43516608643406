import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Suspense } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import AppLoading from '../components/AppLoading';

export default function AuthenticationRequiredLayout() {
  const { i18n } = useTranslation();
  const { error, logout } = useAuth0();

  if (error) {
    // TODO: Add simple error handling first
    logout({
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });
    return;
  }

  const Component = withAuthenticationRequired(Outlet, {
    onRedirecting: () => <AppLoading />,
    loginOptions: {
      authorizationParams: {
        ui_locales: i18n.language === 'zh-HK' ? 'zh-TW' : i18n.language,
      },
    },
  });

  return (
    <Suspense fallback={<AppLoading />}>
      <Component />
    </Suspense>
  );
}
