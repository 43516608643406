import { useAuth0, User } from '@auth0/auth0-react';
import { useLocalStorage } from '@uidotdev/usehooks';

export const getKey = (user: User | undefined, key: string) =>
  `$${user?.['https://app.sleekflow.io/user_id']}-${key}`;

export const useUserLocalStorageKey = (key: string) => {
  const { user } = useAuth0();
  return getKey(user, key);
};

export const useUserLocalStorage = <T>(key: string, initialValue?: T) => {
  const { user } = useAuth0();
  return useLocalStorage<T>(getKey(user, key), initialValue);
};
