import React, { useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import ConversationListEmptyResult from '@/pages/InboxRXJS/ConversationLists/ConversationListEmptyResult';
import { getConversationListItemKey } from '@/pages/InboxRXJS/ConversationLists/ConversationListItem/ConversationListItem';
import ConversationVirtualListItem from '@/pages/InboxRXJS/ConversationLists/ConversationListItem/getConversationVirtualListItem';
import ConversationListSkeleton from '@/pages/InboxRXJS/ConversationLists/ConversationListSkeleton';
import ConversationListVirtualOuterElementType from '@/pages/InboxRXJS/ConversationLists/ConversationListVirtualOuterElementType';
import useSearchConversationWIthUserProfile from '@/pages/InboxRXJS/hooks/useSearchConversationWIthUserProfile';
import { GetConversationsFilter } from '@/services/conversations/models/get-conversations-filter';

interface ConversationWithUserProfileListProps {
  getConversationsFilter: GetConversationsFilter;
  searchKeyword: string;
}

const ConversationWithUserProfileList: React.FC<
  ConversationWithUserProfileListProps
> = ({ getConversationsFilter, searchKeyword }) => {
  const {
    isInitializing,
    searchConversationWithUserProfileResults,
    virtualizerListRange$$,
  } = useSearchConversationWIthUserProfile({
    getConversationsFilter,
    searchKeyword,
  });
  const fixedSizeListRef = useRef<FixedSizeList>(null);
  const fixedSizeListOuterRef = useRef<HTMLDivElement | null>(null);

  if (isInitializing) {
    return <ConversationListSkeleton />;
  }

  if (searchConversationWithUserProfileResults.length === 0) {
    return <ConversationListEmptyResult />;
  }

  return (
    <AutoSizer>
      {({ height, width }: { width: number; height: number }) => (
        <FixedSizeList
          itemKey={(index, data) => {
            return getConversationListItemKey({
              conversationId: data[index].conversation.getId(),
              userProfileId: data[index].userProfile.getId(),
            });
          }}
          width={width}
          ref={fixedSizeListRef}
          itemSize={143}
          height={height}
          overscanCount={25}
          outerRef={fixedSizeListOuterRef}
          outerElementType={ConversationListVirtualOuterElementType}
          itemData={searchConversationWithUserProfileResults}
          itemCount={searchConversationWithUserProfileResults.length}
          onScroll={({ scrollDirection }) => {
            const scrollTop = fixedSizeListOuterRef.current?.scrollTop;
            const scrollHeight = fixedSizeListOuterRef.current?.scrollHeight;
            const clientHeight = fixedSizeListOuterRef.current?.clientHeight;

            if (!scrollTop || !scrollHeight || !clientHeight) {
              return;
            }
            const nearBottom = scrollHeight - scrollTop - clientHeight < 100;

            if (scrollDirection === 'forward' && nearBottom) {
              virtualizerListRange$$.next({
                start: 0,
                end: searchConversationWithUserProfileResults.length - 1,
              });
            }
          }}
        >
          {ConversationVirtualListItem}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default React.memo(ConversationWithUserProfileList);
