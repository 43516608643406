import { RbacDefaultRole } from '@/api/types';
import { useMyProfile } from '@/hooks/useMyProfile';
import { usePermissionWrapper } from '@/hooks/usePermission';
import { PERMISSION_KEY } from '@/constants/permissions';

export const useIntegrationsRoleBasedAccessControl = () => {
  const userProfile = useMyProfile();
  const { check } = usePermissionWrapper({ suspense: true });

  return {
    loading: userProfile.isLoading,
    canViewIntegrationsSettings: check(
      [PERMISSION_KEY.integrationsView],
      [
        [RbacDefaultRole.ADMIN, RbacDefaultRole.SUPER_ADMIN].includes(
          userProfile.data?.roleType as any,
        ),
      ],
    )[0],
  };
};
