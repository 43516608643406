import ButtonBase from '@mui/material/ButtonBase';
import React from 'react';
import { ListChildComponentProps } from 'react-window';

import { LinkBox } from '@/components/LinkOverlay';
import ConversationListItem from '@/pages/InboxRXJS/ConversationLists/ConversationListItem/ConversationListItem';
import { useGetConversationsFilter } from '@/pages/InboxRXJS/hooks/useGetConversationsFilter';
import { ConversationMessageWrapper } from '@/services/conversation-messages/managers/conversation-message-wrapper';
import { ConversationWrapper } from '@/services/conversations/managers/conversation-wrapper';
import { UserProfileWrapper } from '@/services/user-profiles/managers/user-profile-wrapper';

function ConversationVirtualListItem<
  T extends {
    conversation: ConversationWrapper;
    userProfile: UserProfileWrapper;
    message?: ConversationMessageWrapper;
    selected?: boolean;
  }[],
>({ index, style, data }: ListChildComponentProps<T>) {
  const conversationWithUserProfileResult = data[index];
  const globalGetConversationsFilter = useGetConversationsFilter();
  const searchKeyword = globalGetConversationsFilter.searchKeyword;

  return (
    <ButtonBase
      style={style}
      component={LinkBox}
      sx={{
        '& .more-actions-menu': {
          visibility: 'hidden',
          opacity: 0,
        },
        transition: 'visibility opacity 1s',
        '&:hover': {
          backgroundColor: (theme) =>
            theme.palette.componentToken.card.bgSelected,
          '& .more-actions-menu': {
            opacity: 1,
            visibility: 'visible',
          },
        },
        borderBottom: '1px solid',
        borderColor: (theme) => theme.palette.componentToken.card.borderEnabled,
      }}
    >
      <ConversationListItem
        selected={conversationWithUserProfileResult?.selected}
        highlightedSearchKeyword={searchKeyword || ''}
        conversation={conversationWithUserProfileResult.conversation}
        userProfile={conversationWithUserProfileResult.userProfile}
        defaultMessage={conversationWithUserProfileResult?.message}
      />
    </ButtonBase>
  );
}

export default React.memo(ConversationVirtualListItem);
