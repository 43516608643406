import { useCallback, useMemo } from 'react';
import {
  useGetEnabledExperimentalFeatures,
  useGetExperimentalFeatures,
} from '@/api/tenantHub';
import { usePosthogStore } from '@/posthog/posthog-store';

export function useExperimentalFeatures() {
  const availableFeatures = useGetExperimentalFeatures();
  const enabledExperimentalFeatures = useGetEnabledExperimentalFeatures();
  const allUserBetas = usePosthogStore((s) => s.allBetas);
  const allActiveUserBetas = usePosthogStore((s) => s.activeBetas);
  const staffExperimentalFeatures = useMemo(() => {
    return allUserBetas.map((f) => {
      const targetFeature = allActiveUserBetas.find(
        (e) => e.flagKey === f.flagKey,
      );
      return {
        id: f.name,
        name: f.name,
        enabled_feature_id: f.flagKey,
        isEnabled: !!targetFeature || false,
      };
    });
  }, [allActiveUserBetas, allUserBetas]);

  const companyExperimentalFeatures = useMemo(() => {
    return (availableFeatures.data?.data.company_features || []).map((f) => {
      const targetFeature =
        enabledExperimentalFeatures.data?.data.enabled_company_features.find(
          (e) => e.feature_id === f.id,
        );
      return {
        id: f.id,
        name: f.name,
        enabled_feature_id: targetFeature?.id,
        isEnabled: targetFeature?.is_enabled || false,
      };
    });
  }, [
    availableFeatures.data?.data.company_features,
    enabledExperimentalFeatures.data?.data.enabled_company_features,
  ]);

  return {
    staffExperimentalFeatures,
    companyExperimentalFeatures,
    getStaffFeature: useCallback(
      (type: 'inbox_tabs_user') => {
        return staffExperimentalFeatures.find((f) => f.name === type);
      },
      [staffExperimentalFeatures],
    ),
    getCompanyFeature: useCallback(
      (
        type:
          | 'flow_builder_event_tracking_node'
          | 'flow_builder_recommend_reply_node'
          | 'flow_builder_agent_recommend_reply_node'
          | 'flow_builder_agent_evaluate_score_node'
          | 'flow_builder_agent_summarize_node'
          | 'analytics_conversion_analytics'
          | 'ai_agent_management',
      ) => {
        return companyExperimentalFeatures.find((f) => f.name === type);
      },
      [companyExperimentalFeatures],
    ),
  };
}
