import { useAuth0, User } from '@auth0/auth0-react';
import dayjs from 'dayjs';

import { useGetStaffById, useSuspenseGetStaffById } from '@/api/company';
import { UserWorkspace, useUserWorkspaces } from '@/api/tenantHub';

const tryParseJSON = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (_e) {
    return null;
  }
};

type DiveUser = {
  company_id: string;
  expire_at: Date;
  staff_id: number;
  user_id: string;
};

export const CONNECTION_STRATEGY = {
  auth0: 'auth0',
  'google-oauth2': 'google-oauth2',
  apple: 'apple',
  adfs: 'adfs',
  openid: 'openid',
};

export type ConnectionStrategy = keyof typeof CONNECTION_STRATEGY;

export type MyProfile = ReturnType<typeof useMyProfile>;

const userKey = 'https://app.sleekflow.io/user_id';

export function getUserId({ user }: { user?: User }): string {
  const stringifiedDiveUserInfo =
    user?.['https://app.sleekflow.io/login_as_user'];
  const diveUserInfo: DiveUser | null = stringifiedDiveUserInfo
    ? tryParseJSON(stringifiedDiveUserInfo)
    : null;
  const diveTimeRemaining = diveUserInfo?.expire_at
    ? dayjs(diveUserInfo?.expire_at).diff(dayjs(), 'seconds')
    : 0;

  const userId =
    diveTimeRemaining > 0 && diveUserInfo
      ? diveUserInfo.user_id
      : user?.[userKey] || '';
  return userId;
}

const EMPTY: UserWorkspace[] = [];

function useProfileParams() {
  const { user } = useAuth0();
  const { data = EMPTY } = useUserWorkspaces();

  return {
    user,
    userId: getUserId({ user }),
    userWorkspaces: data,
  };
}

export function useMyProfile() {
  const { user, userId, userWorkspaces } = useProfileParams();

  const staffInfo = useGetStaffById({
    userId,
    select: (data) => {
      if (data.length > 0) {
        const staff = data[0];

        // BE should not return integer type but they did
        const associatedTeams = staff.associatedTeams?.map((team) => ({
          ...team,
          id: team.id,
        }));

        const { name: _, ...staffWithoutName } = staff;
        const result = {
          ...staffWithoutName,
          auth0User: user,
          connectionStrategy: user?.[
            'https://app.sleekflow.io/connection_strategy'
          ] as ConnectionStrategy | undefined,
          userWorkspaces: userWorkspaces,
          associatedTeams,
        };

        return result;
      }
      throw new Error('Cannot get staff info');
    },
    staleTime: Infinity,
  });

  return staffInfo;
}

export function useSuspenseMyProfile() {
  const { user, userId, userWorkspaces } = useProfileParams();

  const staffInfo = useSuspenseGetStaffById({
    userId,
    select: (data) => {
      if (data.length > 0) {
        const staff = data[0];

        // BE should not return integer type but they did
        const associatedTeams = staff.associatedTeams?.map((team) => ({
          ...team,
          id: team.id,
        }));

        const { name: _, ...staffWithoutName } = staff;
        const result = {
          ...staffWithoutName,
          auth0User: user,
          connectionStrategy: user?.[
            'https://app.sleekflow.io/connection_strategy'
          ] as ConnectionStrategy | undefined,
          userWorkspaces: userWorkspaces,
          associatedTeams,
        };

        return result;
      }
      throw new Error('Cannot get staff info');
    },
    staleTime: Infinity,
  });

  return staffInfo;
}
