import Fuse from 'fuse.js';
import { useInjection } from 'inversify-react';
import { useObservableState } from 'observable-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetStaffOverview } from '@/api/company';
import { useMyProfile } from '@/hooks/useMyProfile';
import { CompanyService, Team } from '@/services/companies/company.service';
import { StaffCore } from '@/services/companies/types';

export type TeamWithStaffCoreMembers = Team & {
  members: StaffCore[];
  admins: StaffCore[];
};
type IndividualGroupTeam = Omit<TeamWithStaffCoreMembers, 'id'> & {
  id: 'individuals';
};

const EMPTY: StaffCore[] = [];

export function useTeamsSearch<TIncludeIndividuals extends boolean = true>({
  search = '',
  filterMembers = (members) => members,
  filterAwayEmptyTeams = true,
  fetchOnlyMyTeams,
  includeIndividualGroup,
}: {
  filterAwayEmptyTeams?: boolean;
  search?: string;
  fetchOnlyMyTeams?: boolean;
  includeIndividualGroup?: TIncludeIndividuals;
  filterMembers?: (members: StaffCore[]) => StaffCore[];
} = {}) {
  const companyService = useInjection(CompanyService);
  const { t } = useTranslation();

  const { data: me } = useMyProfile();
  const myStaffIdentityId = me?.userInfo?.id;

  const allTeams = useObservableState(
    useMemo(() => companyService.getAllTeams$(), [companyService]),
    [],
  );
  const { data: allStaffs = EMPTY, isLoading: isStaffOverviewLoading } =
    useGetStaffOverview();

  const allStaffsHashmap = useMemo(
    () =>
      allStaffs.reduce(
        (acc, nextVal) => {
          acc[nextVal.staffIdentityId] = nextVal;
          return acc;
        },
        {} as Record<string, StaffCore>,
      ),
    [allStaffs],
  );

  const transformedTeams = useMemo(
    () =>
      allTeams.reduce(
        (acc, team) => {
          acc.push({
            ...team,
            members: Array.from(
              new Map(
                filterMembers(
                  team.members.map((member) => {
                    return (
                      allStaffsHashmap[member.id] ||
                      ({
                        staffIdentityId: member.id,
                        id: member.staffId,
                        firstName: member.firstName,
                        lastName: member.lastName,
                        email: member.email,
                        displayName: member.displayName,
                      } as StaffCore)
                    );
                  }),
                ).map((item) => [item.staffIdentityId, item]),
              ).values(),
            ),
            admins: Array.from(
              new Map(
                filterMembers(
                  team.admins.map((member) => {
                    return (
                      allStaffsHashmap[member.id] ||
                      ({
                        staffIdentityId: member.id,
                        id: member.staffId,
                        firstName: member.firstName,
                        lastName: member.lastName,
                        email: member.email,
                        displayName: member.displayName,
                      } as StaffCore)
                    );
                  }),
                ).map((item) => [item.staffIdentityId, item]),
              ).values(),
            ),
          });

          return acc;
        },
        [] as (Omit<Team, 'members' | 'admins'> & {
          members: StaffCore[];
          admins: StaffCore[];
        })[],
      ),
    [allStaffsHashmap, allTeams, filterMembers],
  );

  const collaboratorGroups = useMemo(
    () =>
      transformedTeams.map((team) => {
        return {
          id: team.id,
          name: team.name,
          members: team.members,
          admins: team.admins,
        };
      }),
    [transformedTeams],
  );

  const uniqueAssociatedMemberIds = useMemo(
    () => [
      ...new Set(
        transformedTeams.flatMap((team) => {
          return [
            ...team.members.map((member) => member.staffIdentityId),
            ...team.admins.map((member) => member.staffIdentityId),
          ];
        }),
      ),
    ],
    [transformedTeams],
  );

  const allCollaboratorsTeams = useMemo(() => {
    return [
      ...collaboratorGroups,
      ...(includeIndividualGroup
        ? [
            {
              id: 'individuals',
              members: filterMembers(allStaffs).filter(
                (staff) =>
                  !uniqueAssociatedMemberIds.includes(staff.staffIdentityId),
              ),
              admins: [] as StaffCore[],
              name: t('individual'),
            },
          ]
        : []),
    ];
  }, [
    collaboratorGroups,
    includeIndividualGroup,
    filterMembers,
    allStaffs,
    uniqueAssociatedMemberIds,
    t,
  ]);

  const teamsFuse = useMemo(
    () =>
      new Fuse(allCollaboratorsTeams ?? [], {
        ignoreLocation: true,
        threshold: 0,
        includeMatches: true,
        keys: [
          'members.firstName',
          'members.lastName',
          'members.displayName',
          'members.email',
          'admins.firstName',
          'admins.lastName',
          'admins.displayName',
          'admins.email',
          'name',
        ],
      }),
    [allCollaboratorsTeams],
  );

  const filteredSearchTeams = useMemo(() => {
    if (!search) return allCollaboratorsTeams;

    return teamsFuse.search(search).map((result) => {
      const team = { ...result.item };
      const matchedMemberIds = new Set();
      const matchedAdminIds = new Set();

      // Collect IDs of matched members and admins
      result.matches?.forEach((match) => {
        if (match.key?.startsWith('members.')) {
          matchedMemberIds.add(match.refIndex);
        } else if (match.key?.startsWith('admins.')) {
          matchedAdminIds.add(match.refIndex);
        }
      });

      // Filter members and admins based on matches
      team.members = team.members.filter((_, index) =>
        matchedMemberIds.has(index),
      );
      team.admins = team.admins.filter((_, index) =>
        matchedAdminIds.has(index),
      );

      return team;
    });
  }, [search, teamsFuse, allCollaboratorsTeams]);

  const filteredTeams = useMemo(
    () =>
      filteredSearchTeams.filter((team) => {
        let result = true;
        if (result && filterAwayEmptyTeams) {
          result = team.members.length !== 0 || team.admins.length !== 0;
        }

        if (result && fetchOnlyMyTeams) {
          result =
            team.members.some(
              (member) => member.staffIdentityId === myStaffIdentityId,
            ) ||
            team.admins.some(
              (admin) => admin.staffIdentityId === myStaffIdentityId,
            );
        }

        return result;
      }),
    [
      filteredSearchTeams,
      filterAwayEmptyTeams,
      fetchOnlyMyTeams,
      myStaffIdentityId,
    ],
  );

  return {
    isLoading: isStaffOverviewLoading,
    allStaffs,
    allStaffsHashmap,
    allTeams: transformedTeams,
    filteredTeams: filteredTeams as TIncludeIndividuals extends true
      ? [...TeamWithStaffCoreMembers[], IndividualGroupTeam]
      : TeamWithStaffCoreMembers[],
  };
}
