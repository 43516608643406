import { useInjection } from 'inversify-react';
import { useObservableState } from 'observable-hooks';
import React, { useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { Subject } from 'rxjs';

import ConversationListEmptyResult from '@/pages/InboxRXJS/ConversationLists/ConversationListEmptyResult';
import { getConversationListItemKey } from '@/pages/InboxRXJS/ConversationLists/ConversationListItem/ConversationListItem';
import ConversationVirtualListItem from '@/pages/InboxRXJS/ConversationLists/ConversationListItem/getConversationVirtualListItem';
import ConversationListSkeleton from '@/pages/InboxRXJS/ConversationLists/ConversationListSkeleton';
import ConversationListVirtualOuterElementType from '@/pages/InboxRXJS/ConversationLists/ConversationListVirtualOuterElementType';
import { GetConversationsFilter } from '@/services/conversations/models/get-conversations-filter';
import { SearchConversationWithMessageAndUserProfileDataSourceManager } from '@/services/conversations/search-conversation-with-message-and-user-profile-data-source-manager';
import { DataSourceListRange } from '@/services/data-sources/models/data-source-list-range';

interface ConversationWithMessageListProps {
  getConversationsFilter: GetConversationsFilter;
  searchKeyword: string;
}

const ConversationWithMessageList: React.FC<
  ConversationWithMessageListProps
> = ({ getConversationsFilter, searchKeyword }) => {
  const searchConversationWithMessageAndUserProfileDataSourceManager =
    useInjection(SearchConversationWithMessageAndUserProfileDataSourceManager);

  const fixedSizeListRef = useRef<FixedSizeList>(null);
  const fixedSizeListOuterRef = useRef<HTMLDivElement | null>(null);

  const [
    ,
    searchConversationWithMessageAndUserProfileResults$,
    isInitializing$,
    virtualizerListRange$$,
  ] = useMemo(() => {
    const _virtualizerListRange$$ = new Subject<DataSourceListRange>();
    const input = {
      ...getConversationsFilter,
      searchKeyword,
    };
    const dataSource =
      searchConversationWithMessageAndUserProfileDataSourceManager.getOrInitDataSource(
        input,
      );

    return [
      dataSource,
      dataSource.setupAndGet$(input, _virtualizerListRange$$),
      dataSource.getIsInitializing$(),
      _virtualizerListRange$$,
    ];
  }, [
    searchConversationWithMessageAndUserProfileDataSourceManager,
    getConversationsFilter,
    searchKeyword,
  ]);
  const searchConversationWithMessageAndUserProfileResults = useObservableState(
    searchConversationWithMessageAndUserProfileResults$,
    [],
  );
  const isInitializing = useObservableState(isInitializing$, false);

  if (isInitializing) {
    return <ConversationListSkeleton />;
  }

  if (searchConversationWithMessageAndUserProfileResults.length === 0) {
    return <ConversationListEmptyResult />;
  }

  return (
    <AutoSizer>
      {({ height, width }: { width: number; height: number }) => (
        <FixedSizeList
          width={width}
          ref={fixedSizeListRef}
          itemSize={143}
          height={height}
          overscanCount={25}
          outerRef={fixedSizeListOuterRef}
          itemKey={(index, data) => {
            return getConversationListItemKey({
              conversationId: data[index].conversation.getId(),
              userProfileId: data[index].userProfile.getId(),
              defaultMessageId: data[index].message?.getId(),
            });
          }}
          outerElementType={ConversationListVirtualOuterElementType}
          itemData={
            searchConversationWithMessageAndUserProfileResults.map((x) => {
              return {
                ...x,
                selected: false,
              };
            }) as any
          }
          itemCount={searchConversationWithMessageAndUserProfileResults.length}
          onScroll={({ scrollDirection }) => {
            const scrollTop = fixedSizeListOuterRef.current?.scrollTop;
            const scrollHeight = fixedSizeListOuterRef.current?.scrollHeight;
            const clientHeight = fixedSizeListOuterRef.current?.clientHeight;

            if (!scrollTop || !scrollHeight || !clientHeight) {
              return;
            }
            const nearBottom = scrollHeight - scrollTop - clientHeight < 100;

            if (scrollDirection === 'forward' && nearBottom) {
              virtualizerListRange$$.next({
                start: 0,
                end:
                  searchConversationWithMessageAndUserProfileResults.length - 1,
              });
            }
          }}
        >
          {ConversationVirtualListItem}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default React.memo(ConversationWithMessageList);
