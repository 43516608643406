/* eslint-disable @typescript-eslint/no-empty-function */
import { LicenseInfo } from '@mui/x-license';
import 'reflect-metadata';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';

import AppRoutes from '@/AppRoutes';
import { setupSentry } from '@/utils/sentry';

import './i18n';

setupSentry();

LicenseInfo.setLicenseKey(
  '2628a354dee015fdf83989f6f390ed97Tz04NjE3NCxFPTE3NDE3Njk5NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

// Disable console logs on production
if (import.meta.env.VITE_USER_NODE_ENV === 'production') {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
  // Don't override in order to capture the error from console then send to sentry
  // console.error = () => {};
}

LicenseInfo.setLicenseKey(
  '2628a354dee015fdf83989f6f390ed97Tz04NjE3NCxFPTE3NDE3Njk5NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  debug: false,
  persistence: 'localStorage',
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>,
);
