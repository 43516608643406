import { Box, ListItem, Menu, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import Icon from '@/components/Icon';
import InfiniteScroll from '@/components/InfiniteScroll';
import { ROUTES } from '@/constants/navigation';
import { useMenuAnchor } from '@/hooks/useMenuAnchor';
import { useRouteWithLocale } from '@/hooks/useRouteWithLocale/useRouteWithLocale';
import { NavMenuItemToolTip } from '@/components/Navbar/NavMenuItemTooltip';
import { getNavMenuItemStyles } from '@/components/Navbar/helpers';
import { usePermissionWrapper } from '@/hooks/usePermission';
import { PERMISSION_KEY } from '@/constants/permissions';
import { useMemo } from 'react';
import { useExperimentalFeatures } from '@/hooks/useExperimentalFeatures';
import {
  FEATURE_FLAG_NAMES,
  useIsCompanyFeatureFlagEnabled,
} from '@/api/featureFlag';

export default function AnalyticsNav() {
  const location = useLocation();
  const routeTo = useRouteWithLocale();
  const { t } = useTranslation();

  const { anchorEl, open, handleAnchorClick, handleAnchorClose } =
    useMenuAnchor();
  const { check } = usePermissionWrapper();

  const [canViewConversationAnalytics, canViewSalesAnalytics] = check([
    PERMISSION_KEY.analyticsConversationView,
    PERMISSION_KEY.analyticsSalesView,
  ]);

  const { getCompanyFeature } = useExperimentalFeatures();
  const analytics_conversion_analytics = getCompanyFeature(
    'analytics_conversion_analytics',
  );

  const { data: isAnalyticsEnabled, isLoading } =
    useIsCompanyFeatureFlagEnabled(FEATURE_FLAG_NAMES.ANALYTICS);

  const analyticsNavItems = useMemo(
    () =>
      [
        {
          to: routeTo(ROUTES.analyticsConversations),
          label: 'Conversations',
          enabled:
            canViewConversationAnalytics && isAnalyticsEnabled && !isLoading,
        },
        {
          to: routeTo(ROUTES.analyticsSales),
          label: 'Sales',
          enabled: canViewSalesAnalytics,
        },
        {
          to: routeTo(ROUTES.analyticsEvents),
          label: 'Events',
          enabled:
            analytics_conversion_analytics?.isEnabled &&
            canViewConversationAnalytics,
        },
      ].filter((item) => item.enabled),
    [
      isAnalyticsEnabled,
      isLoading,
      analytics_conversion_analytics?.isEnabled,
      canViewConversationAnalytics,
      canViewSalesAnalytics,
      routeTo,
    ],
  );

  return (
    <>
      <NavMenuItemToolTip
        title={t('nav.analytics', {
          defaultValue: 'Analytics',
        })}
        placement="right"
        enterDelay={100}
        enterNextDelay={100}
      >
        <ListItem
          key="analytics"
          component="span"
          disablePadding
          sx={(theme) => ({
            ...getNavMenuItemStyles(
              theme,
              location.pathname.includes('analytics'),
            ),
            cursor: 'pointer',
          })}
          onClick={handleAnchorClick}
        >
          <Icon icon="analytics" size={20} sx={{ flexShrink: 0 }} />
        </ListItem>
      </NavMenuItemToolTip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleAnchorClose}
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={250}
        disableAutoFocusItem={true}
        sx={{
          '& .MuiMenu-paper': {
            backgroundColor: 'darkBlue.90',
            borderRadius: '8px',
            minWidth: '260px',
          },
        }}
      >
        <Typography
          variant="subtitle"
          sx={(theme) => ({
            display: 'block',
            color: theme.palette.white,
            textTransform: 'uppercase',
            margin: theme.spacing(1, 2),
          })}
        >
          {t('nav.analytics', {
            defaultValue: 'Analytics',
          })}
        </Typography>
        <InfiniteScroll>
          <Box maxHeight="365px">
            {analyticsNavItems.map((item) => {
              return (
                <NavLink
                  to={item.to}
                  style={{ textDecoration: 'none' }}
                  key={item.label}
                >
                  {({ isActive }) => (
                    <ListItem
                      component="span"
                      disablePadding
                      sx={(theme) => ({
                        ...getNavMenuItemStyles(theme, isActive),
                        padding: theme.spacing(1.5, 2),
                        borderRadius: 'none',
                      })}
                      onClick={handleAnchorClose}
                    >
                      <Typography
                        variant="body1"
                        sx={(theme) => ({
                          marginLeft: theme.spacing(1),
                          color: theme.palette.white,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                        })}
                      >
                        {item.label}
                      </Typography>
                    </ListItem>
                  )}
                </NavLink>
              );
            })}
          </Box>
        </InfiniteScroll>
      </Menu>
    </>
  );
}
