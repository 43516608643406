import { usePermissionWrapper } from '../../../hooks/usePermission';
import { PERMISSION_KEY } from '@/constants/permissions';
import useMyStaff from '../../InboxRXJS/hooks/useMyStaff';
import { RbacDefaultRole } from '../../../api/types';

export const useCommerceHubAccessControl = () => {
  const { check } = usePermissionWrapper({ suspense: true });
  const myStaff = useMyStaff();

  const hasAdminOverride = [
    RbacDefaultRole.ADMIN,
    RbacDefaultRole.SUPER_ADMIN,
  ].includes(myStaff?.roleType as any);

  return {
    canViewCommerceHub: check(
      [PERMISSION_KEY.commerceView],
      [hasAdminOverride],
    )[0],
    canManageCommerceSettings: check(
      [PERMISSION_KEY.commerceSettingsAccess],
      [hasAdminOverride],
    )[0],
  };
};
