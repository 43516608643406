/**
 * Test ids follow the structure {module}-{what}-{elementType}
 * eg. inbox-add-collaborators-menu-trigger, settings-add-profile-picture-button
 * */
export const testIds = {
  // common
  backgroundTaskManager: 'background-task-manager',
  exceedDeviceLimitContinueHere: 'exceed-device-limit-continue-here',
  exceedDeviceLimitContainer: 'exceed-device-limit-container',
  accountSettingsMenuTrigger: 'account-settings-menu-trigger',
  appUpdateDialog: 'app-update-dialog',
  appUpdateDialogCloseButton: 'app-update-dialog-close-button',
  // inbox
  inboxPage: 'inbox-page',
  inboxMenu: 'inbox-menu',
  inboxMenuToggle: 'inbox-menu-toggle',
  inboxMenuToggleClose: 'inbox-menu-toggle-close',
  inboxSearchConversationsFilterTab: 'inbox-search-conversations-filter-tab',
  inboxConversationsSearchbar: 'inbox-conversations-search-bar',
  inboxConversationsStatusFilterButton:
    'inbox-conversations-status-filter-button',
  inboxShopifyStoreDialog: 'inbox-shopify-store-dialog',
  inboxFilterConversationTrigger: 'inbox-filter-conversation-trigger',
  inboxConversationWindow: 'inbox-conversation-window',
  inboxCollaboratorsMenuTrigger: 'inbox-collaborators-menu-trigger',
  inboxFilterLabelsPanel: 'inbox-filter-labels-panel',
  inboxMyInboxMenuItem: 'my-inbox-menu-item',
  inboxCompanyInboxMenuItem: 'company-inbox-menu-item',
  inboxAddCollaboratorsMenu: 'inbox-add-collaborators-menu',
  inboxAddCollaboratorsButton: 'inbox-add-collaborators-button',
  inboxCollaboratorsSearchInput: 'inbox-collaborators-search-input',
  inboxAddCollaboratorsList: 'inbox-add-collaborators-list',
  inboxCollaboratorMenuExistingCollaboratorsList:
    'inbox-collaborator-menu-existing-collaborators-list',
  inboxExistingCollaboratorsList: 'inbox-existing-collaborators-list',
  inboxConversationInput: 'inbox-conversation-input',
  inboxSavedReplySuggestions: 'inbox-saved-reply-suggestions',
  inboxConversationUserProfile: 'inbox-conversation-user-profile',
  inboxSearchConversationList: 'search-conversations-list',
  inboxUnassignedTab: 'inbox-unassigned-tab',
  inboxAssignedTab: 'inbox-assigned-tab',
  // channels
  channelsPage: 'channels-page',
  channelsSecondaryMenu: 'channels-secondary-menu',
  channelsWhatsappCloudAPIChannelsTabs: 'whatsapp-cloudapi-channels-tabs',
  channelsWhatsappCloudAPIFbAccountDropdown:
    'whatsapp-cloudapi-fb-account-dropdown',
  // flow builder
  flowBuilderNodeFormDrawer: 'node-form-drawer',
  // contacts
  contactsCreatePageCreateButton: 'contacts-create-page-create-button',
  contactsCreatePageInput: (fieldName: string) =>
    `contacts-create-page-input-${fieldName}`,
  contactDetailAboutSection: 'contact-detail-about-section',
  // settings
  settingsLayoutHeader: 'settings-layout-header',
  settingsAddProfilePictureButton: 'settings-add-profile-picture-button',
  settingsMenuDrawer: 'settings-menu-drawer',
  settingsMenuToggleButton: 'settings-menu-toggle-button',
  settingsMenuCloseButton: 'settings-menu-close-button',
  settingsMenu: 'settings-menu',
  settingsMenuToggle: 'settings-menu-toggle',
  settingsDataPrivacyTableActionMenu: (id: string | number) =>
    `settings-data-privacy-table-action-menu-${id}`,
  settingsDataPrivacyTableActionMenuButton: (id: string | number) =>
    `settings-data-privacy-table-action-menu-button-${id}`,
  settingsDataPrivacyTableSearchInput:
    'settings-data-privacy-table-search-input',
  settingsDataPrivacyEditPatternDialog:
    'settings-data-privacy-edit-pattern-dialog',
  settingsRolesAndPermissionsDeleteDialog:
    'settings-roles-and-permissions-delete-dialog',
  settingsUserManagementSearchUserInput:
    'settings-user-management-search-user-input',
  // ticketing
  ticketingCreateTicketDialog: 'ticketing-create-ticket-dialog',
  // broadcasts
  broadcastsTableSearchInput: 'broadcasts-table-search-input',
  broadcastsTableCreateNewBroadcastButton:
    'broadcasts-table-create-new-broadcast-button',
  broadcastsTableCreateNewBroadcastDialog:
    'broadcasts-table-create-new-broadcast-dialog',
  broadcastsTableCreateNewBroadcastDialogNextButton:
    'broadcasts-table-create-new-broadcast-dialog-next-button',
  broadcastsTableCreateNewBroadcastChannelSelect:
    'broadcasts-table-create-new-broadcast-channel-select',
  broadcastsTable: 'broadcasts-table',
  broadcastsDetailSaveDraftButton: 'broadcasts-detail-save-draft-button',
  broadcastsDetailPublishButton: 'broadcasts-detail-publish-button',
  broadcastsDetailCloudAPIBroadcastNameInput:
    'broadcasts-detail-cloudapi-broadcast-name-input',
  broadcastsDetailCloudAPIBroadcastTimeRadio:
    'broadcasts-detail-cloudapi-broadcast-time-radio',
  broadcastsDetailCloudAPIMessageTypeSwitch:
    'broadcasts-detail-cloudapi-message-type-switch',
  broadcastsDetailCloudAPIMessageInput:
    'broadcasts-detail-cloudapi-message-input',
  broadcastsDetailCloudAPIMessageInputManualTextInput:
    'broadcasts-detail-cloudapi-message-manual-text-input',
  broadcastsDetailCloudAPIClearTemplateButton:
    'broadcasts-detail-cloudapi-clear-template-button',
} as const;
